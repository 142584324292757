<template>
  <div class="serch-five-point" ref="serchFivePoint">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddTour"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :model="form"
        ref="formInline"
        :rules="rules"
        class="demo-form-inline"
        label-position="left"
        label-width="82px"
      >
        <el-form-item label="班次：">
          <el-input
            v-model="form.numberOfRun"
            placeholder="请输入班次"
          ></el-input>
        </el-form-item>
        <el-form-item label="车站名：">
          <el-input
            v-model="form.busStopName"
            placeholder="请输入车站名"
          ></el-input>
        </el-form-item>
        <el-form-item label="出站日期：" prop="leaveDate">
          <el-date-picker
            v-model="form.leaveDate"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            type="date"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="例检日期：" prop="inspectDate">
          <el-date-picker
            v-model="form.inspectDate"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            type="date"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="选择车辆：">
          <!-- 多选 -->
          <car-tree @getData="getVehIds" ref="carTree" label="id"></car-tree>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column
        prop="leaveDate"
        label="出站日期"
        :formatter="formateTime"
      ></el-table-column>
      <el-table-column prop="numberOfRun" label="班次"></el-table-column>
      <el-table-column prop="inspectDate" label="例检时间"></el-table-column>
      <el-table-column prop="driver" label="驾驶员"></el-table-column>
      <el-table-column prop="carryingLevel" label="装载人数"></el-table-column>
      <el-table-column prop="busStopName" label="车站名"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onViewTour(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >查看</el-button
          >
          <el-button
            @click="onUpdateTour(scope.row)"
            type="text"
            size="small"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            sort="primary"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            v-if="$store.state.menu.nowMenuList.indexOf('删除') >= 0"
            @click="onDeleteTour(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!--新增 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <add-point
        ref="addPoint"
        :itemId="itemId"
        :cph="cph"
        @close="onDialogClose"
        @onLoading="onLoading"
      ></add-point>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          v-show="title.indexOf('详情') === -1"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import { checkLimitDate, formatDay } from '@/common/utils/index'
import { deleteOutbound, queryOutboundList } from '@/api/lib/gps-api.js'

import addPoint from './components/addPoint.vue'

export default {
  name: 'serchFivePoint',
  components: { addPoint, carTree },
  data() {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (value) {
        if (new Date() < value) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      timeLimit: 60,
      form: {
        numberOfRun: null,
        busStopName: '',
        cph: '',
        leaveDate: null,
        inspectDate: null,
        currentPage: 1,
        pageSize: 10
      },
      pickerOptions: {
        disabledDate(time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        leaveDate: [{ validator: validateDateForward, trigger: 'change' }],
        inspectDate: [{ validator: validateDateForward, trigger: 'change' }]
      },
      itemId: null,
      cph: '',
      title: '',
      dialogVisible: false,
      searchLoading: false,
      editLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.serchFivePoint.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    // 点击查询
    onSearch() {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    // 获取车辆数据
    getVehIds(val) {
      this.form.vehicleIds = [...val]
    },
    //获取数据
    getListByField() {
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          data.leaveDate = data.leaveDate
            ? `${formatDay(this.form.leaveDate)} 00:00:00`
            : null
          data.inspectDate = data.inspectDate
            ? `${formatDay(this.form.inspectDate)} 00:00:00`
            : null
          queryOutboundList(data)
            .then((res) => {
              if (res.code === 1000) {
                this.tableData = res.data.list
                this.total = res.data.total
                if (res.data.list.length === 0 && data.currentPage !== 1) {
                  this.form.currentPage -= 1
                  this.getListByField()
                }
              } else {
                this.tableData = []
                this.total = 0
                this.$message.error(res.msg)
              }
              this.searchLoading = false
            })
            .catch(() => {
              this.searchLoading = false
            })
        } else {
          this.searchLoading = false
        }
      })
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
      this.getListByField()
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
      this.getListByField()
    },
    // 新增
    onAddTour() {
      this.title = '新增出站'
      this.cph = ''
      this.itemId = null
      this.dialogVisible = true
    },
    // 查看
    onViewTour(row) {
      this.title = '出站详情'
      this.cph = row.cph
      this.itemId = row.id
      this.dialogVisible = true
    },
    // 编辑
    onUpdateTour(row) {
      this.title = '编辑出站'
      this.cph = row.cph
      this.itemId = row.id
      this.dialogVisible = true
    },
    //  保存
    onSave() {
      this.$refs.addPoint.onSave()
    },
    onLoading(flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose(flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //  删除
    onDeleteTour(row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [h('span', null, '确定删除这条数据？')]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteOutbound(row.id).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.getListByField()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },
    //时间显示
    formateTime(row, column, cellValue) {
      return cellValue == null || cellValue == '' ? '-' : formatDay(cellValue)
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.serch-five-point {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-gps {
  .serch-five-point {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
